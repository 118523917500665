import React from 'react';

const Blog2 = () => {
  return (
    <div>
      <p>This is the content of Blog 2.</p>
    </div>
  );
};

export default Blog2;
