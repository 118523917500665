// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  border-top: 3px solid #ccc;
  padding-top: 30px;
}

.timeline-line {
  position: absolute;
  top: 0;
  left: 0px;
  width: 3px;
  height: 100%;
  background-color: #ccc;
  z-index: 0;
}

@media (max-width: 768px) {
  .timeline {
    padding-left: 0;
    align-items: center;
    /* Center the timeline items */
    padding-top: 30px;
  }

  .timeline-line {
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
  }

  .search-bar {
    width: 90%;
    /* Full width for the search bar on mobile */
    margin-left: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .timeline {
    padding-left: 0;
    align-items: center;
    /* Keep items centered on smaller screens */
    padding-top: 20px;
  }

  .timeline-line {
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    /* Slightly thinner for better fit on small screens */
  }

  .search-bar {
    width: 90%;
    /* Make sure search bar takes nearly full width */
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Timeline.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;EACnB;;EAEA;IACE,SAAS;IACT,2BAA2B;IAC3B,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,4CAA4C;IAC5C,cAAc;IACd,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;IACnB,2CAA2C;IAC3C,iBAAiB;EACnB;;EAEA;IACE,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,qDAAqD;EACvD;;EAEA;IACE,UAAU;IACV,iDAAiD;IACjD,mBAAmB;EACrB;AACF","sourcesContent":[".timeline {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding-left: 50px;\n  border-top: 3px solid #ccc;\n  padding-top: 30px;\n}\n\n.timeline-line {\n  position: absolute;\n  top: 0;\n  left: 0px;\n  width: 3px;\n  height: 100%;\n  background-color: #ccc;\n  z-index: 0;\n}\n\n@media (max-width: 768px) {\n  .timeline {\n    padding-left: 0;\n    align-items: center;\n    /* Center the timeline items */\n    padding-top: 30px;\n  }\n\n  .timeline-line {\n    left: 50%;\n    transform: translateX(-50%);\n    width: 3px;\n  }\n\n  .search-bar {\n    width: 90%;\n    /* Full width for the search bar on mobile */\n    margin-left: 0;\n    margin-bottom: 20px;\n  }\n}\n\n@media (max-width: 480px) {\n  .timeline {\n    padding-left: 0;\n    align-items: center;\n    /* Keep items centered on smaller screens */\n    padding-top: 20px;\n  }\n\n  .timeline-line {\n    left: 50%;\n    transform: translateX(-50%);\n    width: 3px;\n    /* Slightly thinner for better fit on small screens */\n  }\n\n  .search-bar {\n    width: 90%;\n    /* Make sure search bar takes nearly full width */\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
