import Project1 from '../pages/ProjectPages/Project1';

const projectSummaries = [
    {
        id: 1,
        title: 'Project 1 Deducer',
        summary: 'What is that about',
        component: Project1, 
    },
];

export default projectSummaries;
