import React from 'react';

const Blog1 = () => {
  return (
    <div>
      <h2>This is the content of Blog 1.</h2>
      <p>How to </p>
    </div>
  );
};

export default Blog1;