const timelineData = [
  {
    title: "Wie Alles Begann?",
    description: "Erste Website",
    details:
      "Meine Begeisterung für Computer begann, als ich 7 Jahre alt war, im selben Jahr, als ich meinen ersten Computer sah. Damals hatten wir noch die klobigen CRT-Monitore und Windows XP.<br>" +
      "Schnell war ich von Computern fasziniert und begann, Technikzeitschriften statt National Geographic Kids zu kaufen (sorry, Natur!).<br>" +
      "Mit 10 Jahren habe ich meine erste Website mit einer Low-Code-Plattform erstellt. Es war eine epische Seite voller Comics, Fotos, Nachrichten und – das Highlight – Widgets!",
    date: "2006",
    tag: "milestone",
    path: "code"
  },
  {
    title: "Abenteuer in der Oberschule",
    description: "Ahmet Keleşoğlu Anatolian High School - Istanbul, Türkei",
    details:
      "Ganz normale Oberschuljahre :) Meistens gefüllt mit Sport, dem Abhängen mit Freunden und dem Genießen der goldenen Zeiten.",
    date: "2010-2014",
    tag: "education",
    path: "",
  },
  {
    title: "Reise der Kampfkünste",
    description: "",
    details:
      "Ich habe während meines ersten Jahres in der Oberschule mit Karate angefangen. Das Gefühl, mich ständig zu verbessern, ist der Hauptgrund, warum ich weiterhin Kampfsport betreibe. Ständige Verbesserung und Meditation gehen Hand in Hand.",
    date: "2010-2024",
    tag: "interest",
    path: "",
  },
  {
    title: "Deutsch Lernen",
    description: "Deutsch lernen",
    details: "Ich erreichte das B1-Niveau in Deutsch während der Oberschule und in einer Sprachschule in Wien. Langsam aber sicher arbeite ich mich weiter nach oben!",
    date: "2014 - Gegenwart",
    tag: "language",
    path: ""
  },
  {
    title: "Erster Schritt in den Aktienmarkt",
    description: "Fundamentale und technische Analyse bei Yaşar Erdinç",
    details:
      "Ich nahm an einem Aktienmarkt-Zertifizierungsprogramm von einem der besten Finanzprofessoren der Türkei, Yaşar Erdinç, teil. Es war mein Einstieg in die Welt der Finanzen und eröffnete mir eine neue Welt voller Zahlen und Analysen.",
    date: "2014",
    tag: "certification",
    path: "finance",
  },
  {
    title: "Universitätsjahre",
    description: "Istanbul Universität - Wirtschaftswissenschaften, Bachelor-Abschluss",
    details:
      "Die Universität war eine Zeit des ständigen Lernens, sowohl akademisch als auch persönlich. Zwischen Vorlesungen und Nebenprojekten verbesserten sich meine Zeitmanagementfähigkeiten erheblich.",
    date: "2014-2018",
    tag: "education",
    path: "management",
  },
  {
    title: "Unternehmensgründungstraining",
    description: "Teilnehmer",
    details: "Während meiner Universitätsjahre nahm ich an verschiedenen Unternehmerschulungskursen teil und sammelte einige Zertifikate auf dem Weg.",
    date: "2016",
    tag: "education",
    path: "management"
  },
  {
    title: "Paradict - Eine neue Perspektive",
    description: "Gründer & Entwickler",
    details:
      "Paradict war mein erstes unternehmerisches Projekt, das darauf abzielte, ein globales Wörterbuch zu schaffen, das die Menschen dazu ermutigt, die Welt mit anderen Augen zu sehen.<br>" +
      "Ich habe drei weitere Freunde rekrutiert und das gesamte Projekt organisiert. Dies war auch meine erste Erfahrung im Projekt- und Teammanagement. Da ich keinen Entwickler finden konnte - und kein Geld hatte, um einen zu bezahlen :)- wurde es auch meine erste Entwicklererfahrung.<br>" +
      "Ich begann mit HTML und CSS, erkannte dann, dass ich auch JavaScript, dann PHP, dann AJAX lernen musste... Viele Nächte habe ich mich allein im Dunkeln mit dem Computer unterhalten, während ich mich mit dem Code abmühte.<br>" +
      "Am Ende habe ich das Projekt fertiggestellt; alles war bereit, aber leider kamen Schule und andere Projekte dazwischen, also legte ich es beiseite... Aber mein erstes 'Baby' werde ich nie vergessen...",
    date: "Jul 2016 - Dez 2016",
    tag: "work, milestone, project",
    path: "code, management"
  },
  {
    title: "Gedik Investment Praktikum",
    description: "Praktikant",
    details:
      "Ich arbeitete mit dem Forschungs- und Entwicklungsteam zusammen und half bei der Erstellung täglicher und monatlicher Finanzberichte. Das reichte mir nicht, und ich konnte nicht aufhören, mit Technologie zu arbeiten, also habe ich ein automatisiertes Handelssystem in C# entwickelt, weil, warum nicht?",
    date: "Mär 2015 - Mär 2016",
    tag: "work",
    path: "code"
  },
  {
    title: "Unternehmensgründungszertifikat",
    description: "TÜBİTAK-1601",
    details: "Sechs Monate intensives Unternehmerschulungstraining, bei dem ich die Feinheiten des Startens und Führens eines Unternehmens gelernt habe.",
    date: "Jun 2016",
    tag: "certification",
    path: "management",
  },
  {
    title: "Deducer - Sherlock Holmes Spiel",
    description: "Gründer & Entwickler",
    details:
      "Ich entwickelte ein webbasiertes Spiel mit PHP für Leute, die ihren inneren Sherlock Holmes kanalisieren möchten. Deduktionsfähigkeiten erforderlich!",
    date: "Mär 2016 - Jun 2016",
    tag: "project",
    path: "code, management"
  },

  {
    title: "Interrail-Abenteuer",
    description: "Euro-Explorer",
    details:
      "Im Sommer 2016, als ich 20 Jahre alt war, habe ich eine zweiwöchige Interrail-Reise durch Europa unternommen. Die Menschen, Kulturen und die Freiheit auf den Schienen hinterließen einen bleibenden Eindruck.<br>" +
      "Es war meine beste Reiseerfahrung, die mir zeigte, wie viele Dinge es auf der Welt zu entdecken gibt. Außerdem war ich von der europäischen Kultur fasziniert. Diese Reise war der Auslöser für viele zukünftige Reisen nach Europa.",
    date: "Jul 2016",
    tag: "milestone, interest",
    path: ""
  },
  {
    title: "Einführung in die Datenwissenschaft in Python",
    description: "Universität von Michigan",
    details: "Ich habe mit diesem Zertifikat erste Einblicke in die Datenwissenschaft gewonnen und bin seitdem fasziniert von Daten.",
    date: "Apr 2017",
    tag: "certification",
    path: "code",
  },
  {
    title: "ST Trading - Assistent für Warenbeschaffung & Operations Manager",
    description: "Assistent für Warenbeschaffung / Operations Manager",
    details:
      "Ich half bei der Kommunikation zwischen Lieferanten und Kunden in über 5 Ländern, um rechtzeitige Lieferungen und erfolgreiche Verhandlungen auf internationalen Messen sicherzustellen. Ich war immer noch Student, und diese Erfahrung war ein Augenöffner für mich. Meine Kommunikationsfähigkeiten verbesserten sich dadurch enorm.",
    date: "Mär 2017 - Sep 2019",
    tag: "work",
    path: "management"
  },
  {
    title: "Pragnas - Ein neues unternehmerisches Kapitel",
    description: "Mitbegründer / Webentwickler",
    details:
      "Pragnas war mein erstes ernsthaftes Unternehmen, das ich mit einem Freund gegründet habe. Durch diese Firma habe ich mich in der Softwareentwicklung stark verbessert. Ich war in allen Bereichen des Unternehmens tätig, einschließlich Marketing. Durch unsere entwickelten Produkte und Websites erreichten wir über 20 Kunden. Leider mussten wir das Unternehmen gegen Ende meines Studiums aufgeben.<br>" +
      "<b>Erfolge</b><br>" +
      "Erstellung maßgeschneiderter CRM- und ERP-Systeme, Gestaltung von Dashboards und Optimierung von Geschäftsprozessen.",
    date: "Mai 2017 - Sep 2019",
    tag: "work",
    path: "code, management"
  },
  {
    title: "Sirdaryo Consultancy - RPA-Entwickler",
    description: "RPA-Entwickler",
    details:
      "Ich war die dritte Person, die bei der Gründung von Sirdaryo dabei war – ein kleines Startup, das sich jetzt zu einem Unternehmen mit fast 20 Mitarbeitern entwickelt hat und nationale und internationale Kunden bedient. Von Anfang an Teil dieses Unternehmens zu sein und großartige Mentoren zu haben, hat mich sowohl beruflich als auch in meiner Kommunikation mit Kunden weiterentwickelt. Neben meiner Rolle als RPA-Entwickler leitete ich ein Team und schulte neue Mitarbeiter.<br>" +
      "Die Leitung eines Teams zur Entwicklung von Automatisierungsprozessen und die Verwaltung des UiPath Orchestrator war eine Herausforderung, aber auch sehr lohnend. Oh, und ich habe ein voll funktionsfähiges Framework entworfen, das selbst UiPath neidisch machen würde.",
    date: "Sep 2019 - Gegenwart",
    tag: "work, milestone",
    path: "rpa, management"
  },
  {
    title: "Erstes RPA-Projekt",
    description: "RPA-Entwickler",
    details:
      "Mein erstes RPA-Projekt werde ich nie vergessen. Ich musste einen vollständigen Rechnungsprozess entwickeln, der fast 3 Wochen dauerte, und dieser Prozess funktionierte auf mindestens 6-7 herausfordernden Bildschirmen (einer davon war sogar AS400!). Obwohl ich nur grundlegendes Wissen hatte, führte das Durcharbeiten in den Nächten zu enormem Wachstum.",
    date: "2019",
    tag: "work, milestone",
    path: "RPA"
  },
  {
    title: "Notion-Berater",
    description: "Notion-Master",
    details:
      "Ich war sehr beeindruckt von Notions unglaublicher Anpassungsfähigkeit und Datenbankverwaltung. Ich beschloss, es im Unternehmen zu implementieren, und habe jetzt ein System eingerichtet, mit dem das Unternehmen alle CRM- und BPM-Aspekte verwalten kann. Jeden Tag arbeite ich daran, es weiter zu verbessern.",
    date: "Sep 2019 - Gegenwart",
    tag: "work",
    path: "management"
  },

  {
    title: "UiPath-Akademie-Zertifikate",
    description: "UiPath",
    details: "Zertifiziert in allem von Process Mining bis Test Automation – mein Skillset wächst stetig!",
    date: "Jan 2023",
    tag: "certification",
    path: "rpa",
  },
  {
    title: ".NET-Training",
    description: "Lernender",
    details: "Nachdem ich die Bedeutung von .NET für UiPath erkannt habe, habe ich intensiv daran gearbeitet. In die Welt von .NET eingetaucht, um meinen Software-Engineering-Werkzeugkasten zu erweitern. Ein bisschen von allem hilft enorm.",
    date: "2021",
    tag: "education",
    path: "code, rpa"
  },
  {
    title: "UiPath Certified Advanced RPA Developer",
    description: "UiPath",
    details: "Zertifiziert als fortgeschrittener RPA-Entwickler! Mehr Bots, mehr Automatisierung!",
    date: "Jan 2023",
    tag: "certification",
    path: "rpa",
  },
  {
    title: "RPA - SAP Hana Migration",
    description: "RPA-Entwickler",
    details:
      "Ich war an der Migration von fast 30 RPA-Prozessen von SAP R3 auf SAP Hana für ein internationales Unternehmen beteiligt. Das Verständnis und die Migration komplexer Prozesse halfen mir, mich im Bereich SAP und Finanzen weiterzuentwickeln.",
    date: "2022",
    tag: "work, project",
    path: "rpa"
  },
  {
    title: "KoçZer - RPA-Entwickler",
    description: "RPA-Entwickler",
    details:
      "Ich habe als dedizierter RPA-Entwickler im größten Beschaffungsunternehmen der Türkei gearbeitet. Habe rund 20 Prozesse entwickelt und über 80 RPA-Prozesse betreut.",
    date: "2024",
    tag: "work, project",
    path: "rpa"
  },
  {
    title: "KoçZer - Wartung",
    description: "RPA-Entwickler",
    details:
      "Während ich RPA-Prozesse entwickelte und optimierte, half ich bei der Verbesserung der Cybersicherheit bei KoçZer und stellte sicher, dass alle Automatisierungen sicher waren.",
    date: "2024",
    tag: "work, project",
    path: "rpa"
  },
  {
    title: "CI/CD-Projekt",
    description: "Entwickler",
    details:
      "Ich habe ein CI/CD-Projekt geleitet, um den Softwarebereitstellungsprozess zu optimieren und zu verbessern.",
    date: "2024",
    tag: "work, project",
    path: "code, rpa"
  }
];

export default timelineData;

