// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-post-container {
  padding: 40px;
  width: 100%;
  /* min-width yerine width kullanıyoruz */
  margin: 20px auto;
  background-color: #2a2a2a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  /* Taşma durumlarını önlemek için */
}

.blog-post-title {
  font-size: 2.8rem;
  color: rgb(107, 178, 219);
  ;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/BlogPostPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,wCAAwC;EACxC,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;;EAEzB,mBAAmB;EACnB,kBAAkB;EAClB,6BAA6B;EAC7B,oBAAoB;AACtB","sourcesContent":[".blog-post-container {\n  padding: 40px;\n  width: 100%;\n  /* min-width yerine width kullanıyoruz */\n  margin: 20px auto;\n  background-color: #2a2a2a;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n  overflow: hidden;\n  /* Taşma durumlarını önlemek için */\n}\n\n.blog-post-title {\n  font-size: 2.8rem;\n  color: rgb(107, 178, 219);\n  ;\n  margin-bottom: 20px;\n  text-align: center;\n  border-bottom: 2px solid #444;\n  padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
