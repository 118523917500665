const timelineData = [
  {
    title: "How It All Started?",
    description: "First Website",
    details:
      "My enthusiasm for computers sparked when I was 7, the same year I first met one. Back then, we had those bulky CRT monitors and Windows XP! " +
      "I quickly got hooked on computers, and I started buying tech magazines such as CHIP and PCNet instead of National Geographic Kids.<br>" +
      "At age 10, I created my first website using a low-code platform. It was an epic site full of comics, photos, news, and the fan favorite—widgets!",
    date: "2006",
    tag: "milestone",
    path: "code"
  },
  {
    title: "High School Adventures",
    description: "Ahmet Keleşoğlu Anatolian High School - Istanbul, Turkey",
    details:
      "Just regular high school years :) Mostly filled with sports, hanging out with friends, and enjoying the golden days.",
    date: "2010-2014",
    tag: "education",
    path: "",
  },
  {
    title: "Martial Arts Journey",
    description: "",
    details:
      "I started Karate during my first year of high school. The feeling of pushing myself to improve has been the main reason I continue to practice martial arts. Constant improvement and meditation go hand-in-hand.",
    date: "2010-2024",
    tag: "interest",
    path: "",
  },
  {
    title: "Deutsch Lernen",
    description: "Learning German",
    details: "I reached a B1 level in German during high school and while attending a language school in Vienna. Working my way up slowly but surely!",
    date: "2014 - Present",
    tag: "language",
    path: ""
  },
  {
    title: "First Step Into the Stock Market",
    description: "Fundamental and Technical Analysis by Yaşar Erdinç",
    details:
      "I attended a stock market certification program by one of Turkey’s top finance professors, Yaşar Erdinç. It was my introduction to finance and opened up a new world of numbers and analysis.",
    date: "2014",
    tag: "certification",
    path: "finance",
  },
  {
    title: "University Years",
    description: "Istanbul University - Economics, Bachelor's Degree",
    details:
      "University was a time of nonstop learning, both academically and personally. Between lectures and side projects, my time management skills leveled up significantly.",
    date: "2014-2018",
    tag: "education",
    path: "management",
  },
  {
    title: "Entrepreneurship Training",
    description: "Participant",
    details: "During my university years, I participated in various entrepreneurship courses and collected a few certificates along the way.",
    date: "2016",
    tag: "education",
    path: "management"
  },
  {
    title: "Paradict - A New Perspective",
    description: "Founder & Developer",
    details:
      "Paradict was my first entrepreneurial project aimed at creating a global dictionary that encourages people to see the world through different eyes.<br>" +
      "I gathered 3 more friends and organized the whole project. This was also my first project and team management experience. Due to not being able to find a developer—and no money to pay to hire one :)- it became my development experience as well.<br>" +
      "I started learning HTML and CSS, then realized I needed JavaScript, then PHP, then AJAX... Lots of nights I found myself talking to the computer alone in the dark, struggling to code.<br>" +
      "Eventually, I finished the project; everything was ready to go, but unfortunately, school and some other projects got in the way, so I shelved it... But I won't forget my first baby...",
    date: "Jul 2016 - Dec 2016",
    tag: "work, milestone, project",
    path: "code, management"
  },
  {
    title: "Gedik Investment Internship",
    description: "Intern",
    details:
      "I worked with the Research and Development team, helping to create daily and monthly financial reports. That was not enough for me, and I couldn't stand working without technology, so I built an automated trading system in C# because, why not?",
    date: "Mar 2015 - Mar 2016",
    tag: "work",
    path: "code"
  },
  {
    title: "Entrepreneurship Certification",
    description: "TÜBİTAK-1601",
    details: "Six months of extended entrepreneurship training, learning the ins and outs of starting and running a business.",
    date: "Jun 2016",
    tag: "certification",
    path: "management",
  },
  {
    title: "Deducer - Sherlock Holmes Game",
    description: "Founder & Developer",
    details:
      "I developed a web-based game with PHP for people who want to channel their inner Sherlock Holmes. Deduction skills required!",
    date: "Mar 2016 - Jun 2016",
    tag: "project",
    path: "code, management"
  },

  {
    title: "Interrail Adventure",
    description: "Euro-Explorer",
    details:
      "In the summer of 2016, when I was 20, I embarked on a 2-week Interrail journey across Europe. The people, cultures, and freedom on the rails left a lasting impression.<br>" +
      "It was my best travel experience, making me realize how many things in the world we must see. Also, I was amazed by European culture. That would spark my countless future trips to Europe.",
    date: "Jul 2016",
    tag: "milestone, interest",
    path: ""
  },
  {
    title: "Introduction to Data Science in Python",
    description: "University of Michigan",
    details: "Dipped my toes into Data Science with this certification, and I’ve been fascinated with data ever since.",
    date: "Apr 2017",
    tag: "certification",
    path: "code",
  },
  {
    title: "ST Trading - Assistant Merchandiser & Operations Manager",
    description: "Assistant Merchandiser / Operation Manager",
    details:
      "I helped manage communications between suppliers and customers in over 5 countries, ensuring timely deliveries and successful negotiations at international fairs. I was still a student during this experience, and it was an eye-opening job for me. My communication skills excelled because of it.",
    date: "Mar 2017 - Sep 2019",
    tag: "work",
    path: "management"
  },
  {
    title: "Pragnas - A New Entrepreneurial Chapter",
    description: "Co-Founder / Web Developer",
    details:
      "Pragnas was my first serious venture, co-founded with a friend. With this company, I greatly improved my software skills. I was involved in every aspect of projects and the company, including marketing and promotion. Through the products we developed and the websites we created, we reached over 20 clients. Unfortunately, we had to wrap things up towards the end of university due to other commitments.<br>" +
      "At Pragnas, I handled everything from business process analysis to web development.<br>" +
      "<b>Achievements</b><br>" +
      "Built custom CRM and ERP systems, designed dashboards, and optimized business processes.",
    date: "May 2017 - Sep 2019",
    tag: "work",
    path: "code, management"
  },
  {
    title: "Sirdaryo Consultancy - RPA Developer",
    description: "RPA Developer",
    details:
      "I was the third person to join Sirdaryo during its startup phase—a baby startup that has now transformed into a company with nearly 20 employees, serving national and international companies in Turkey. Being part of this corporate company from the very beginning and having high-quality mentors not only developed me professionally but also improved my communication with clients through my role as a consultant. Besides being an RPA developer, managing a team and providing training to newcomers made me a well-rounded individual. By bringing countless projects and ideas to life for the development of the company, I helped it reach this point.<br>" +
      "Leading a team in developing automation processes and managing UiPath Orchestrator has been both challenging and rewarding. Oh, and I designed a fully-featured framework that even UiPath would envy.",
    date: "Sep 2019 - Present",
    tag: "work, milestone",
    path: "rpa, management"
  },
  {
    title: "First RPA Project",
    description: "RPA Developer",
    details:
      "My first RPA project is actually the one I'll never forget. I had to develop a full invoicing process that took almost 3 weeks, and this process operated on at least 6-7 challenging screens (one of them was even AS400!). Although I had only basic knowledge beforehand, working through the nights on this project led to tremendous growth.",
    date: "2019",
    tag: "work, milestone",
    path: "RPA"
  },
  {
    title: "Notion Consultant",
    description: "Notion Master",
    details:
      "I was greatly impressed by Notion's incredible customizability and database management capabilities. I decided to implement it within the company, and now I've set up a system where the company can manage all CRM and BPM aspects. I'm continually working to improve it every day.",
    date: "Sep 2019 - Present",
    tag: "work",
    path: "management"
  },

  {
    title: "UiPath Academy Certifications",
    description: "UiPath",
    details: "Certified in everything from Process Mining to Test Automation—my skillset keeps expanding!",
    date: "Jan 2023",
    tag: "certification",
    path: "rpa",
  },
  {
    title: ".NET Training",
    description: "Learner",
    details: "Realizing its importance for UiPath, I did a lot of .NET work. Dived into .NET to expand my software engineering toolkit. A little bit of everything goes a long way.",
    date: "2021",
    tag: "education",
    path: "code, rpa"
  },
  {
    title: "UiPath Certified Advanced RPA Developer",
    description: "UiPath",
    details: "Certified as an Advanced RPA Developer! More bots, more automation!",
    date: "Jan 2023",
    tag: "certification",
    path: "rpa",
  },
  {
    title: "RPA - SAP Hana Migration",
    description: "RPA Developer",
    details:
      "I was involved in the migration of nearly 30 RPA processes from SAP R3 to SAP Hana for an international company. Understanding and learning complex processes and carrying out the migrations helped me develop in SAP and finance areas.",
    date: "2022",
    tag: "work, project",
    path: "rpa"
  },
  {
    title: "KoçZer - RPA Developer",
    description: "RPA Developer",
    details:
      "Worked as a dedicated RPA developer in the biggest purchasing company in Turkey. Developed around 20 processes and handled over 80 RPA processes.",
    date: "2024",
    tag: "work, project",
    path: "rpa"
  },
  {
    title: "KoçZer - Maintenance",
    description: "RPA Developer",
    details:
      "While developing and optimizing RPA processes, helped to improve cybersecurity at KoçZer, making sure all the automation was secure.",
    date: "2024",
    tag: "work, project",
    path: "rpa"
  },
  {
    title: "CI/CD Project",
    description: "Developer",
    details:
      "Led a CI/CD project to streamline and enhance the software deployment process.",
    date: "2024",
    tag: "work, project",
    path: "code, rpa"
  }
];

export default timelineData;
