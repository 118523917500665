import Blog1 from '../pages/BlogPages/blog1';
import Blog2 from '../pages/BlogPages/blog2';

const blogSummaries = [
    {
        id: 1,
        title: 'Blog 1 Title',
        summary: 'asdasd',
        component: Blog1, 
    },
    {
        id: 2,
        title: 'Blog 2 Title',
        component: Blog2,
    },
];

export default blogSummaries;
